@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap');

.App {
  /* text-align: center;
  margin-top: 50px; */

}

body {
  font-family: "Montserrat", sans-serif;
  color: rgb(62, 62, 62);
  background-color: #ffffff;
  /* background-color: #e1eaf3; */
}

.container-inline {
  margin-left: 55%;
  margin-top: 4%;
  width: 35%;
}

.fixed-container {
  position: fixed;
  top: 20%;
  left: 10%;
  width: 35%;
  height: 35%;
  background-color: #fff;
  z-index: 0;
  /* Adjust z-index as needed */
  /* transition: top 0.3s ease; Add transition effect for smooth movement */
}

.unfixed-container {
  position: absolute;
  top: 65%;
  left: 10%;
  width: 35%;
  height: 35%;
}

.main-text {
  font-weight: 600;
}

.name-main-text {
  background-image: linear-gradient(to right, #00ffff, #d9e021);
  /* Gradient colors */
  -webkit-background-clip: text;
  /* Apply background to text */
  color: transparent;
  /* Hide original text */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.view-more-details-toggle {
  text-decoration: underline;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: #fff;
}

.header-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the content horizontally */
  padding: 2%;
  /* Add padding as needed */
  background-color: transparent;
}

.logo {
  height: 70px;
  /* Adjust the height of your logo */
  width: 120px;
}

.logo-mobile {
  height: 50px;
  width: 80px;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}


legend {
  font-size: 24px;
  margin-bottom: 10px;
}

.buy-now-button {
  width: 80%;
  height: 30%;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  color: rgb(62, 62, 62);
  border: none;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  background-image: linear-gradient(to right, #00ffff, #d9e021);
  border-radius: 50px;
  font-size: 1.2em;
  font-weight: 600;

}


.buy-now-button:hover {
  background-image: linear-gradient(to right, #00ffff, #d9e021);
}

.product-details {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: left;
}

.product-details h2 {
  margin-bottom: 10px;
}

.product-details p {
  margin-bottom: 5px;
}

.price-cut {
  text-decoration: line-through;
  /* color: #696969;  Optional: you can change the color to indicate it's a discounted price */
}

.color-options {
  margin-top: 10px;
}

.color-options label {
  margin-right: 10px;
}

.color-options input[type="radio"] {
  margin-right: 5px;
}

.horizontal-line {
  width: 90%;
  height: 1%;
  border-top: 1px solid #cecece;
  /* You can adjust the color and style of the line here */
  margin: 10px auto;
  /* Adjust margin as needed */
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.footer {
  /* width: 100%; */
  background-color: rgb(62, 62, 62);
  color: #fff;
  padding: 20px;
  text-align: center;
}

.social-icons {
  margin-bottom: 10px;
}

.social-icons a {
  color: #fff;
  font-size: 24px;
  margin-right: 10px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #00f;
  /* Change color on hover as needed */
}

.dropdown {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.icon-drop {
  align-self: center;
  cursor: pointer;
}

.container {
  display: flex;
  flex-wrap: wrap;
}


.button-row {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap to the next row */
}

.outline-button {
  border: 1px solid #c7c7c7;
  color: #000000;
  padding: 10px 20px;
  background-color: transparent;
  cursor: pointer;
  margin: 5px;
  /* Add margin for spacing between buttons */
  border-radius: 10px;
}

.active-outline-button {
  border: 1px solid #09cad7;
  color: #09cad7;
  background-color: #000000;
  padding: 10px 20px;
  background-color: transparent;
  cursor: pointer;
  margin: 5px;
  /* Add margin for spacing between buttons */
  border-radius: 10px;
}

.active-outline-button:hover {
  border: 1px solid #e4e6eb;
  background-color: #f0f0f0;
  /* Change background color on hover if needed */
}

.outline-button:hover {
  background-color: #f0f0f0;
  /* Change background color on hover if needed */
}

.modal-overlay-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-2 {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.modal-content button:hover {
  background-color: #0056b3;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.gradient-button {
  width: 50%;
  background-image: linear-gradient(to right, #ff7e5f, #feb47b);
  border: none;
  color: white;
  padding: 8px 16px;
  /* Adjust padding as needed */
  border-radius: 4px;
  /* Add rounded corners if desired */
  cursor: pointer;
  outline: none;
  display: block;
  margin: 0 auto;
  transition: background-color 0.3s ease;
  /* Add transition effect for smooth hover */
}

.gradient-button:hover {
  background-image: linear-gradient(to right, #ff9779, #ffcb98);
  /* Adjust hover colors */
}

.input-bottom-border {
  border: none;
  border-bottom: 1px solid #ccc;
  /* Add a bottom border */
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  /* Ensure padding and border are included in width */
}

.input-bottom-border:focus {
  outline: none;
  border-bottom: 2px solid #007bff;
  /* Change bottom border color on focus */
}


.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  max-width: 600px;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

input,
textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

select {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}